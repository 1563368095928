var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "block",
                        staticStyle: {
                          padding: "0px 0px 7px 0px",
                          "border-bottom": "solid 1px lightgray",
                        },
                      },
                      [
                        _c("span", { staticClass: "has-text-weight-bold" }, [
                          _vm._v(_vm._s(_vm.pageheading)),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "block" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.handleFormSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("div", [
                            _vm.error !== null
                              ? _c("div", { staticClass: "has-text-danger" }, [
                                  _vm._v(" " + _vm._s(_vm.error) + " "),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(0),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.name,
                                      expression: "input.name",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", name: "name" },
                                  domProps: { value: _vm.input.name },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "control" }, [
                                !_vm.carrier.code
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.code,
                                          expression: "input.code",
                                        },
                                      ],
                                      staticClass: "input",
                                      attrs: {
                                        type: "text",
                                        name: "name",
                                        maxlength: "5",
                                        minlength: "2",
                                      },
                                      domProps: { value: _vm.input.code },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.input,
                                            "code",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.carrier.code
                                  ? _c("input", {
                                      staticClass: "input",
                                      attrs: {
                                        type: "text",
                                        name: "name",
                                        maxlength: "5",
                                        minlength: "2",
                                        disabled: _vm.carrier.code,
                                      },
                                      domProps: { value: _vm.input.code },
                                    })
                                  : _vm._e(),
                              ]),
                              _c(
                                "small",
                                { staticStyle: { color: "#eaae5e!important" } },
                                [
                                  _vm._v(
                                    "Short Name cannot be modified after saving the carrier."
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { id: "buttongroup" },
                            },
                            [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button is-accent has-text-white",
                                      attrs: {
                                        disabled: !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["CARRIERS"],
                                          2
                                        ),
                                        type: "submit",
                                      },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button is-light",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.go(-1)
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "column",
                    staticStyle: { "max-width": "60%" },
                  }),
                ]),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Carrier Name "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Short Name "),
      _c("small", { staticStyle: { "font-size": "10px" } }, [
        _vm._v("(Maximum of 5 characters)"),
      ]),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }